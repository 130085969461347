import * as React from "react";
import { HeaderNewComponent } from "../new-components/header/Header";
import FooterNewComponent from "../new-components/footer/Footer";
import LazyProductImage from "../components/lazy-product-image/lazy-product-image";
import { trackPage } from "../api";
import { useTranslation } from "next-i18next";
import MetaHeader from "../components/meta-header/meta-header";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "../next-i18next.config.js";
function ErrorScreen(props: { store: "fr" | "us"; language: string }) {
  const { t, i18n } = useTranslation("error");
  const language = i18n.language;

  let store = props.store;

  function getHomeUrl() {
    let url = "/";
    const store = props.store === "fr" ? "eu" : "us";
    url = `/${props.language ?? language}/${store}`;

    window.open(url, "_self");
  }
  if (typeof window !== "undefined") {
    const queryParams = new URLSearchParams(window.location.search);
    // Log the full query string and parsed parameters
    if (queryParams.get("store")) {
      store = queryParams.get("store") as "fr" | "us";
    }
  }

  React.useEffect(() => {
    trackPage("Error");
  }, []);

  return (
    <div className="error-page">
      <MetaHeader title={t("title")} description={t("text")} />
      <div id="content">
        <div className="beige">
          <HeaderNewComponent
            variant="beige"
            disableScroll={true}
            store={store}
            language={i18n.language}
          />
          <div className="container center-center">
            <LazyProductImage
              width="200"
              height="200"
              alt="404"
              src="https://public.choose.app/website/new/images/404.png"
              srcSet={`https://public.choose.app/website/new/images/404@2x.png 2x,https://public.choose.app/website/new/images/404@3x.png 3x`}
            />
            <h1 dangerouslySetInnerHTML={{ __html: t("headline") }} />
            <p>{t("text")}</p>
            <button onClick={getHomeUrl} className="black">
              {t("btn")}
            </button>
          </div>
        </div>
        <FooterNewComponent variant="white" store={props.store} />
      </div>
    </div>
  );
}
export const getStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations(
        "en",
        ["error", "common"],
        nextI18NextConfig as any
      )),
    },
  };
};

export default ErrorScreen;
